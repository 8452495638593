<template>
    <div class="flexPage bind-bank-card">
        <div class="flexHead">
            <navbar :params="pageInfo">
                <template #right>
                    <span @click="save" class="nav-right-text">{{ $t('other.translate109') }}</span>
                </template>
            </navbar>
        </div>
        <van-cell-group :border="false">
            <van-cell :title="$t('other.translate103')">
                <template #default>
                    <van-field placeholder="redmi_1" :border="false" input-align="right" disabled />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate94')" @click.native="bankVisible = true" is-link
                :value="formData.bank ? formData.bank : $t('other.translate113')" />
            <van-cell :title="$t('other.translate112')">
                <template #default>
                    <van-field v-model="formData.branch" :placeholder="$t('other.translate114')" :border="false" input-align="right" />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate98')">
                <template #default>
                    <van-field v-model="formData.cardNo" :placeholder="$t('other.translate115')" :border="false" input-align="right" />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate116')">
                <template #default>
                    <van-field v-model="formData.twiceCardNo" :placeholder="$t('other.translate117')" :border="false" input-align="right" />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate104')">
                <template #default>
                    <van-field v-model="formData.jyPassword" :placeholder="$t('other.translate106')" type="password" :border="false"
                        input-align="right" />
                </template>
            </van-cell>
        </van-cell-group>

        <van-action-sheet v-model="bankVisible" :actions="bankNameList" @cancel="bankVisible = false" @select="selectBank"
            :cancel-text="$t('other.translate118')" close-on-click-action />
    </div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";

export default {
    data() {
        return {
            pageInfo: {
                customTitle: this.$t('other.translate119'),
                navbarStyle: "blue",
                backname: "payment",
            },
            formData: {
                bank: '',
                branch: '',
                jyPassword: '',
                realName: 'redmi_1',
                cardNo: '',
                twiceCardNo: ''
            },
            bankVisible: false,
            bankNameList: [
                {
                    value: '中国工商银行',
                    name: '中国工商银行',
                },
                {
                    value: '中国农业银行',
                    name: '中国农业银行',
                },
                {
                    value: '中国建设银行',
                    name: '中国建设银行',
                },
                {
                    value: '中国邮政储蓄银行',
                    name: '中国邮政储蓄银行',
                },
                {
                    value: '招商银行',
                    name: '招商银行',
                },
                {
                    value: '中国银行',
                    name: '中国银行',
                },
                {
                    value: '交通银行',
                    name: '交通银行',
                },
                {
                    value: '中信银行',
                    name: '中信银行',
                },
                {
                    value: '华夏银行',
                    name: '华夏银行',
                },
                {
                    value: '中国民生银行',
                    name: '中国民生银行',
                },
                {
                    value: '广发银行',
                    name: '广发银行',
                },
                {
                    value: '平安银行',
                    name: '平安银行',
                },
                {
                    value: '兴业银行',
                    name: '兴业银行',
                },
                {
                    value: '上海浦东发展银行',
                    name: '上海浦东发展银行',
                },
                {
                    value: '浙商银行',
                    name: '浙商银行',
                },
                {
                    value: '渤海银行',
                    name: '渤海银行',
                },
                {
                    value: '恒丰银行',
                    name: '恒丰银行',
                },
                {
                    value: '花旗银行',
                    name: '花旗银行',
                },
                {
                    value: '渣打银行',
                    name: '渣打银行',
                },
                {
                    value: '汇丰银行',
                    name: '汇丰银行',
                },
                {
                    value: '中国光大银行',
                    name: '中国光大银行',
                },
                {
                    value: '上海银行',
                    name: '上海银行',
                },
                {
                    value: '江苏银行',
                    name: '江苏银行',
                },
                {
                    value: '重庆银行',
                    name: '重庆银行',
                },
                {
                    value: '天津银行',
                    name: '天津银行',
                },
                {
                    value: '厦门银行',
                    name: '厦门银行',
                },
                {
                    value: '城市商业银行',
                    name: '城市商业银行',
                },
                {
                    value: '农村商业银行',
                    name: '农村商业银行',
                },
                {
                    value: '徽商银行',
                    name: '徽商银行',
                },
            ],
        }
    },
    components: { navbar },
    methods: {
        selectBank(event) {
            this.formData.bank = event.value;
        },
        save() {
            let param = {};
            param["bank"] = this.formData.bank;
            param["branch"] = this.formData.branch;
            param["jyPassword"] = this.formData.jyPassword;
            param["realName"] = this.formData.realName;
            param["cardNo"] = this.formData.cardNo;
            this.$http.post(this.host + "/uc/approve/bind/bank", param).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.$toast(this.$t('other.translate110'));
                    this.$mts.goto('payment');
                } else {
                    this.$toast(resp.message);
                }
            });
        }
    }
}

</script>
<style>
.bind-bank-card {
    height: 100vh;
    background: #F8FAFD;
}

.bind-bank-card .flexHead .nav-right-text {
    color: #FFFFFF;
    font-size: 14px;
}

.bind-bank-card .van-cell-group .van-cell {
    display: flex;
    align-items: center;
}

.bind-bank-card .van-cell-group .van-cell .van-cell__value .van-field {
    padding: unset;
}

.bind-bank-card .van-cell-group .van-cell .van-cell__value .van-field .van-field__value .van-field__body input {
    margin-bottom: unset;
    border: unset;
    padding: unset;
    height: unset;
}

.bind-bank-card .van-action-sheet__content {
    height: 76.3359vw;
}

.bind-bank-card .van-action-sheet__cancel:before {
    display: none;
}
</style>